<template>
  <div v-if="hasPendingChanges" class="mb-10">
    <BaseAlert>
      {{ $t("USER_PROFILE.PENDING_CHANGE.ALERT") }}
    </BaseAlert>

    <PendingChangeDetail
      :pending-change="pendingChange"
      v-for="pendingChange of pendingChanges"
      :key="pendingChange.id"
    />
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import BaseAlert from "@components/Base/BaseAlert.vue";
import PendingChangeDetail from "@views/Restricted/UserProfile/Show/partials/PendingUserChanges/partials/PendingChangeDetail.vue";

export default {
  name: "PendingUserChanges",
  components: { PendingChangeDetail, BaseAlert },

  data() {
    return {
      isLoading: true,
      pendingChanges: [],
    };
  },

  computed: {
    hasPendingChanges() {
      return !isEmpty(this.pendingChanges);
    },
  },

  methods: {
    async retrievePendingChanges() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.authUser.listPersonChange();
        this.pendingChanges = data.data;
      } finally {
        this.isLoading = false;
      }
    },
  },

  async created() {
    await this.retrievePendingChanges();
  },
};
</script>
