<template>
  <v-row class="mb-4" v-if="user">
    <v-col cols="12">
      <PendingUserChanges />
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col cols="auto" align-self="center">
          <v-avatar color="primary" size="80">
            <span class="white--text text-h3 pt-2">{{ initials }}</span>
          </v-avatar>
        </v-col>

        <v-col cols="auto" align-self="center">
          <BaseHeadline size="3">
            {{ user.salutation }}
            {{ fullName }}
          </BaseHeadline>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="12" v-if="user.emailMain || user.emailSub">
      <div class="mb-2">
        <BaseText overline color="gray600">
          {{ $t("USER_PROFILE.SHOW.LABEL_EMAIL") }}
        </BaseText>
      </div>
      <BaseText>{{ user.emailMain || user.emailSub }}</BaseText>
    </v-col>

    <v-col cols="12" md="6" v-if="!!user.city && !!user.zipCode">
      <div class="mb-2">
        <BaseText overline color="gray600">
          {{ $t("USER_PROFILE.SHOW.LABEL_ZIP_CITY") }}
        </BaseText>
      </div>
      <BaseText>{{ user.zipCode }} {{ user.city }}</BaseText>
    </v-col>

    <v-col cols="12" md="6" v-if="user.street">
      <div class="mb-2">
        <BaseText overline color="gray600">
          {{ $t("USER_PROFILE.SHOW.LABEL_STREET") }}
        </BaseText>
      </div>
      <BaseText>{{ user.street }}</BaseText>
    </v-col>

    <v-col cols="12" md="6" v-if="user.phone">
      <div class="mb-2">
        <BaseText overline color="gray600">
          {{ $t("USER_PROFILE.SHOW.LABEL_PHONE") }}
        </BaseText>
      </div>
      <BaseText>{{ user.phone }}</BaseText>
    </v-col>

    <v-col cols="12" md="6" v-if="user.mobile">
      <div class="mb-2">
        <BaseText overline color="gray600">
          {{ $t("USER_PROFILE.SHOW.LABEL_MOBILE") }}
        </BaseText>
      </div>
      <BaseText>{{ user.mobile }}</BaseText>
    </v-col>
  </v-row>
</template>

<script>
import initialsHelper from "@/helper/initialsHelper";
import PendingUserChanges from "./PendingUserChanges/index.vue";

export default {
  name: "UserData",
  components: { PendingUserChanges },

  props: {
    user: {
      type: undefined,
      required: true,
      default: null,
    },
  },

  computed: {
    fullName() {
      return `${this.user.firstName} ${this.user.lastName}`;
    },

    initials() {
      return initialsHelper(this.user.firstName, this.user.lastName);
    },
  },
};
</script>
