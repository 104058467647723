<template>
  <BaseAlert v-if="user && hasDeleteRequest" type="warning">
    {{ infoLabel }}
  </BaseAlert>
</template>

<script>
import dateFilter from "@mixins/dateFilter";

export default {
  props: {
    user: {
      type: Object,
      required: false,
      default: () => ({
        userDeleteRequest: {
          deleteAt: null,
        },
      }),
    },
  },
  mixins: [dateFilter],
  computed: {
    hasDeleteRequest() {
      return !!this.user.userDeleteRequest;
    },
    infoLabel() {
      if (this.hasDeleteRequest && this.user.userDeleteRequest.deleteAt) {
        return this.$t("USER_PROFILE.DELETE_REQUEST.EXECUTED_AT", {
          deleteAt: this.date(this.user.userDeleteRequest.deleteAt),
        });
      }

      if (this.hasDeleteRequest && !this.user.userDeleteRequest.deleteAt) {
        return this.$t("USER_PROFILE.DELETE_REQUEST.EXISTS_INFO_LABEL");
      }

      return null;
    },
  },
  methods: {
    reload() {
      this.$emit("reload");
    },
  },
};
</script>
