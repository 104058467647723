<template>
  <ModuleSubPage title="USER_PROFILE.TITLE" :loading="isLoading">
    <v-row no-gutters v-if="!isLoading">
      <v-col cols="12" v-if="!hasUserProfile">
        <BaseAlert> {{ $t("USER_PROFILE.SHOW.NO_PROFILE") }} </BaseAlert>
      </v-col>

      <v-col cols="12" v-if="hasUserProfile">
        <v-row>
          <v-col cols="12">
            <DeleteRequestInfo :user="userProfile" />
          </v-col>

          <v-col cols="12" class="mb-10">
            <BaseButtonTertiary @click="goToEditPage">
              {{ $t("USER_PROFILE.SHOW.EDIT_BUTTON_LABEL") }}
            </BaseButtonTertiary>
          </v-col>

          <v-col cols="12">
            <User :user="userProfile" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import User from "@views/Restricted/UserProfile/Show/partials/User.vue";
import DeleteRequestInfo from "@views/Restricted/UserProfile/Show/partials/DeleteRequest/DeleteRequestInfo";
import BaseAlert from "@components/Base/BaseAlert.vue";

export default {
  name: "UserProfileHome",

  components: {
    BaseAlert,
    DeleteRequestInfo,
    ModuleSubPage,
    User,
  },

  data() {
    return {
      userProfile: null,
      hasUserProfile: true,
      isLoading: true,
    };
  },

  methods: {
    goToEditPage() {
      this.$router.push({ name: "UserProfileUpdate" });
    },

    async showAuthUserProfile() {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.authUser.showPerson();
        this.userProfile = data.data;
      } catch (err) {
        console.error(err);
        this.hasUserProfile = false;
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.showAuthUserProfile();
  },
};
</script>
