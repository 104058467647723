<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t("USER_PROFILE.PENDING_CHANGE.DETAIL.TITLE") }}: #{{ pendingChange.id }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="6" v-for="(value, key) in profileData" :key="key">
              <v-row>
                <v-col>
                  <BaseText bold>
                    {{ $t("USER_PROFILE.PENDING_CHANGE.DETAIL.KEYS." + key.toUpperCase()) }}
                  </BaseText>
                </v-col>

                <v-col>
                  <BaseText>
                    {{ value }}
                  </BaseText>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import BaseText from "@components/Base/BaseText.vue";

export default {
  name: "PendingChangeDetail",
  components: { BaseText },

  props: {
    pendingChange: {
      type: Object,
      required: true,
    },
  },

  computed: {
    profileData() {
      return {
        salutation: this.pendingChange.salutation,
        firstName: this.pendingChange.firstName,
        lastName: this.pendingChange.lastName,
        street: this.pendingChange.street,
        zipCode: this.pendingChange.zipCode,
        city: this.pendingChange.city,
        emailMain: this.pendingChange.emailMain,
        emailSub: this.pendingChange.emailSub,
        phone: this.pendingChange.phone,
        mobile: this.pendingChange.mobile,
      };
    },
  },
};
</script>
